import { useMutation, useQueryClient } from "react-query";
import { useDispatch } from "react-redux";
import { API_MSP_V2, QUERIES } from "consts";
import { useHttpClient } from "contexts/httpClient";
import withUrlParams from "utils/withUrlParams";
import { pollTaskStatus } from "queryHooks/useTaskStatus/useTaskStatus";

export default function useUpdateMSP(mspId, config) {
  const httpClient = useHttpClient();
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const updateMSP = (data) =>
    new Promise(async (resolve, reject) => {
      try {
        const res = await httpClient.put(
          withUrlParams(API_MSP_V2, { mspId }),
          data
        );
        pollTaskStatus(httpClient, res?.task?.id, resolve, reject, dispatch);
      } catch (err) {
        reject(err);
      }
    });

  return useMutation((data) => updateMSP(data), {
    ...config,
    onSuccess: (data) => {
      queryClient.resetQueries(QUERIES.getMspList);
      queryClient.invalidateQueries("MSP");
      config?.onSuccess?.(data);
    },
  });
}
