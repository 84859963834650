import React, { useMemo } from "react";
import { getIn } from "formik";
import {
  FormGroup,
  Label,
  Col,
  Input,
  Tooltip,
  Icon,
  Select,
  DatePicker,
} from "orion";
import InformationSVG from "assets/icons/information.svg";
import PhoneInput from "shared/PhoneInput/PhoneInput";

import GenericOptionsSelect from "components/GenericOptionsSelect";
import styles from "./Field.module.scss";
import {
  genericSelectTypes,
  minDate as defaultMinDate,
  minYear,
  maxYear,
} from "consts";

export const getIsInputVaild = (formikProps, name) =>
  getIn(formikProps.touched, name) &&
  getIn(formikProps.errors, name) !== undefined;

const Field = ({
  name,
  label,
  placeholder,
  options,
  formikProps,
  type = "text",
  tooltip,
  tooltipProps,
  isMulti,
  dataTestId,
  full,
  fixedInitialValues,
  disabled,
  interceptor,
  minDate,
  maxDate,
  onChange,
  productId,
  downgradeAllowed,
  isEndpointAllowedForEdition,
  disabledEditions,
  onBlur,
}) => {
  const isInvalid = useMemo(
    () => getIsInputVaild(formikProps, name),
    [formikProps.errors, formikProps.touched, name]
  );

  const ChosenInput =
    type === "number" ? (
      <Input
        type="number"
        className={styles.numberField}
        name={name}
        id={name}
        validationProps={formikProps}
        isInvalid={isInvalid}
        data-testid={dataTestId}
        placeholder={placeholder}
        disabled={disabled}
        onBlur={onBlur}
      />
    ) : type === "text" ? (
      <Input
        type="text"
        name={name}
        id={name}
        validationProps={formikProps}
        isInvalid={isInvalid}
        data-testid={dataTestId}
        placeholder={placeholder}
        disabled={disabled}
        onBlur={onBlur}
      />
    ) : type === "phone" ? (
      <>
        <PhoneInput
          name={name}
          value={getIn(formikProps.values, name)}
          formikProps={formikProps}
          isInvalid={isInvalid}
          onChange={(phone) => {
            formikProps.setFieldValue(name, phone);
          }}
          dataTestId={dataTestId}
          placeholder={placeholder}
          disabled={disabled}
        />
      </>
    ) : type === genericSelectTypes.storageRegions ||
      type === genericSelectTypes.skus ||
      type === genericSelectTypes.editions ||
      type === genericSelectTypes.datasources ? (
      <GenericOptionsSelect
        isMulti={isMulti}
        name={name}
        type={type}
        placeholder={placeholder}
        formikProps={formikProps}
        dataTestId={dataTestId}
        isInvalid={isInvalid}
        disabled={disabled}
        fixedInitialValues={fixedInitialValues}
        onChange={onChange}
        productId={productId}
        downgradeAllowed={downgradeAllowed}
        isEndpointAllowedForEdition={isEndpointAllowedForEdition}
        disabledEditions={disabledEditions}
      />
    ) : type === "select" ? (
      <Select
        isMulti={isMulti}
        name={name}
        type="select"
        options={options}
        placeholder={placeholder}
        isInvalid={isInvalid}
        validationProps={formikProps}
        value={getIn(formikProps.values, name)}
        dataTestId={dataTestId}
        disabled={disabled}
      />
    ) : (
      <DatePicker
        isClearable={false}
        dateFormat="MMM dd, yyyy"
        name={name}
        type="select"
        placeholder={placeholder}
        isInvalid={isInvalid}
        validationProps={formikProps}
        value={getIn(formikProps.values, name)}
        dataTestId={dataTestId}
        minDate={minDate}
        maxDate={maxDate}
        startYear={minYear}
        endYear={maxYear}
        disabled={disabled}
        onChange={(nativeDate) => {
          if (interceptor) {
            formikProps.setFieldValue(name, interceptor(nativeDate));
          }
        }}
      />
    );
  return (
    <FormGroup row className="oui-mb-0">
      <Label muted text={label} sm={3} />
      <Col
        sm={type === "number" ? 2 : full ? 9 : 5}
        className={`oui-mt-auto ${isInvalid ? "oui-mb-1" : "oui-mb-3"}`}
      >
        {ChosenInput}
      </Col>
      {tooltip && (
        <Col
          sm={3}
          className={`oui-pl-0 oui-my-auto ${styles.tooltipContainer}`}
        >
          <Tooltip
            maxWidth={275}
            placement="right"
            text={tooltip}
            {...tooltipProps}
          >
            <Icon src={InformationSVG} />
          </Tooltip>
        </Col>
      )}
    </FormGroup>
  );
};

Field.defaultProps = {
  minDate: defaultMinDate,
  type: "text",
};

export default Field;
