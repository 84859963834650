import { Cookies } from "react-cookie";

import { timezones } from "./utils/timezone";

export const API_URL = window._env_.APP_API;
export const V2_API_URL = window._env_.APP_API_V2;
export const V2_MSP_API_URL = window._env_.APP_MSP_API_V2;
export const MSP_API_URL = window._env_.APP_MSP_API;
export const AUTH_API_URL = window._env_.APP_AUTH_API;
export const LOGIN_REDIRECT_URL = window._env_.APP_LOGIN_REDIRECT_URL;
export const BASENAME = window._env_.APP_BASENAME;
export const IS_STORYBOOK = process.env.STORYBOOK_IS_ACTIVE;
export const DOMAIN = window._env_.APP_DOMAIN;

export const API_MSP_LIST_V2 = V2_API_URL + "/msps";
export const API_MSP_V2 = V2_API_URL + "/msps/:mspId";
export const API_SERVICE_PLAN_WORKLOAD_LIST = V2_MSP_API_URL + "/workloads";
export const API_MSP_SUSPEND = MSP_API_URL + "/msps/:mspId/suspend";
export const API_MSP_UNSUSPEND = MSP_API_URL + "/msps/:mspId/unsuspend";
export const API_MSP_PERMISSIONS = MSP_API_URL + "/msps/:mspId/permissions";
export const API_MSP_UPDATE_EMAIL =
  MSP_API_URL + "/msps/:mspId/admin/:adminId/update";
export const API_STORAGE_REGION_LIST = V2_API_URL + "/storage-regions";
export const API_SKU_LIST = API_URL + "/skus";
export const API_EDITION_LIST = API_URL + "/editions";
export const API_LOGOUT = API_URL + "/logout";
export const API_TASK = V2_MSP_API_URL + "/tasks/:taskId";

export const API_USER_CURRENT = AUTH_API_URL + "/user-current";
export const API_REFRESH_TOKEN = AUTH_API_URL + "/token";

export const genericSelectTypes = {
  storageRegions: "storageRegions",
  skus: "skus",
  editions: "editions",
  products: "products",
  datasources: "datasources",
};

export const dataSourceTypes = {
  M365: "Microsoft 365",
  "Hybrid Workloads": "Hybrid Workloads",
  Endpoints: "Endpoints",
  "Google Workspace": "Google Workspace",
};

export const dataSourceTypesToID = {
  "Hybrid Workloads": 1,
  M365: 2,
  Endpoints: 3,
  "Google Workspace": 4,
};

export const dataSourceIdToDSName = {
  1: "Hybrid Workloads",
  2: "M365",
  3: "Endpoints",
  4: "Google Workspace",
};

export const productIDToName = {
  1: "Hybrid Workloads",
  2: "SaaS Apps and Endpoints",
};

export const dataSourceIdToName = {
  1: "Hybrid Workloads",
  2: "Microsoft 365",
  3: "Endpoints",
  4: "Google Workspace",
};

export const PRODUCT_ID = {
  hybridWorkloads: 1,
  saasAppsAndEndpoints: 2,
};

export const CAM_JOBS_STATUS = {
  pending: 1,
  running: 2,
  paused: 3,
  finished: 4,
};

export const TimezoneOptions = Object.keys(timezones)
  .sort((a, b) => a.localeCompare(b))
  .map((key) => ({
    label: key,
    value: key,
  }));

export const PAGE_SIZE = 50;

export const COLOR_WARNING = "#FF9900";
export const COLOR_DANGER = "#DC3545";

export const dateFormatter = new Intl.DateTimeFormat("en-US", {
  dateStyle: "medium",
});

export const minDate = new Date();
export const minYear = minDate.getFullYear() - 1;
export const maxYear = minYear + 8;

export const cookies = new Cookies();

export const genericErrorMessage = "Unexpected error occured";

export const QUERIES = {
  getMspList: "get-msp-list-all-page",
};
