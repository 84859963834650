import React, { cloneElement } from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import { modals as orionModals } from "slices/modalsSlice";
import { closeModal } from "slices/modalsSlice";

const FormModalRenderer = ({ components }) => {
  const modals = useSelector(orionModals);
  const dispatch = useDispatch();
  return modals.map((modal) => {
    const { key } = modal;
    const Component = components[key];
    if (!Component) {
      return null;
    }
    const newProps = {
      closeModal: () => dispatch(closeModal(key)),
      modalKey: key,
      isOpen: true,
      ...modal,
    };
    return cloneElement(<Component />, newProps);
  });
};

FormModalRenderer.propTypes = {
  components: PropTypes.object,
};

export default FormModalRenderer;
