import React from "react";
import PropTypes from "prop-types";
import ReactDOM from "react-dom";

class Portal extends React.Component {
  componentWillUnmount() {
    if (this.defaultNode) {
      document.body.removeChild(this.defaultNode);
    }
    this.defaultNode = null;
  }

  render() {
    if (!this.props.node && !this.defaultNode) {
      this.defaultNode = document.createElement("div");
      this.defaultNode.className = this.props.className;
      document.body.appendChild(this.defaultNode);
    }
    return ReactDOM.createPortal(
      this.props.children,
      this.props.node || this.defaultNode
    );
  }
}

Portal.defaultProps = {
  className: "",
};

Portal.propTypes = {
  children: PropTypes.node.isRequired,
  node: PropTypes.any,
  className: PropTypes.string,
};

export default Portal;
