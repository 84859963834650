import React, {
  useMemo,
  useRef,
  useEffect,
  useState,
  useCallback,
} from "react";
import { getIn } from "formik";
import { Select } from "orion";

import {
  dataSourceIdToName,
  genericSelectTypes,
  productIDToName,
  PRODUCT_ID,
} from "consts";
import useStorageRegionList from "queryHooks/useStorageRegionList/useStorageRegionList";
import useSkuList from "queryHooks/useSkuList/useSkuList";
import useEditionList from "queryHooks/useEditionList/useEditionList";
import useMspProducts from "queryHooks/useMspProducts/useMspProducts";
import useProductDatasource from "queryHooks/useMspProducts/useProductDatasource";

const orderOptions = (values) => {
  return values
    .filter((v) => v.isFixed)
    .concat(values.filter((v) => !v.isFixed));
};

const GenericOptionsSelect = ({
  name,
  placeholder,
  formikProps,
  isMulti,
  dataTestId,
  type,
  isInvalid,
  fixedInitialValues,
  onChange,
  disabled,
  isClearable,
  productId,
  downgradeAllowed,
  isEndpointAllowedForEdition,
  disabledEditions,
  ...rest
}) => {
  const initialValue = useMemo(() => {
    const rawInitialValue = formikProps
      ? getIn(formikProps.values, name)
      : rest.value;
    return isMulti ? orderOptions(rawInitialValue) : rawInitialValue;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const selectRef = useRef();
  const { current: useData } = useRef(
    type === genericSelectTypes.storageRegions
      ? useStorageRegionList
      : type === genericSelectTypes.editions
      ? useEditionList
      : type === genericSelectTypes.skus
      ? useSkuList
      : type === genericSelectTypes.products
      ? useMspProducts
      : type === genericSelectTypes.datasources
      ? useProductDatasource
      : () => ({})
  );
  const { data } = useData({
    isForOptions: true,
    ...(type === genericSelectTypes.datasources ? { productId } : {}),
  });

  const options = useMemo(() => {
    if (type === genericSelectTypes.storageRegions) {
      if (productId) {
        const regionForDataSource = (data || []).filter(
          (d) => d.productID === productId
        )[0];
        return (regionForDataSource?.regions || []).map((region) => ({
          value: region,
          label: region,
        }));
      } else {
        const inSyncRegions = (data || []).filter(
          (d) => d.productID === PRODUCT_ID.saasAppsAndEndpoints
        )[0]?.regions;
        const hybridWorkloadsRegions = (data || []).filter(
          (d) => d.productID === PRODUCT_ID.hybridWorkloads
        )[0]?.regions;
        const flattenRegions = [
          ...(inSyncRegions || []),
          ...(hybridWorkloadsRegions || []),
        ];

        const uniqueRegions = [
          ...new Set(flattenRegions.map((region) => region)),
        ];

        return (uniqueRegions || []).map((region) => ({
          label: region,
          value: region,
        }));
      }
    }

    if (type === genericSelectTypes.products) {
      return (data || []).map((product) => ({
        value: product.productID,
        label: productIDToName[product.productID],
      }));
    }

    if (type === genericSelectTypes.datasources && productId) {
      return (data || [])?.map((datasource) => {
        const disabled =
          !isEndpointAllowedForEdition && datasource.datasourceID === 3;
        return {
          value: datasource.datasourceID,
          label: dataSourceIdToName[datasource.datasourceID],
          isDisabled: disabled,
        };
      });
    }

    if (type === genericSelectTypes.skus) {
      return (data?.list || [])?.map((sku) => {
        const disabled = disabledEditions && disabledEditions?.includes(sku.id);
        return {
          value: sku.id,
          label: sku.name,
          isDisabled: disabled,
        };
      });
    }

    if (
      !downgradeAllowed &&
      type === genericSelectTypes.editions &&
      initialValue?.value === "commercial"
    ) {
      return (data?.list || []).map((edition) => ({
        value: edition.id,
        label: edition.name,
        isDisabled: edition.id !== initialValue.value,
      }));
    }

    return (data?.pages?.[0]?.list || data?.list || data || []).map(
      (optionValue) => ({
        value: optionValue.id || optionValue.name || optionValue,
        label: optionValue.name || optionValue,
        isDisabled: !!optionValue.isDisabled,
        isFixed: fixedInitialValues
          ? initialValue.some(({ value: initialValueElem }) => {
              return (
                initialValueElem ===
                (optionValue.id || optionValue.name || optionValue)
              );
            })
          : false,
      })
    );
  }, [
    data,
    fixedInitialValues,
    initialValue,
    productId,
    type,
    downgradeAllowed,
    isEndpointAllowedForEdition,
    disabledEditions,
  ]);

  const styles = useMemo(() => {
    return {
      ...(type === genericSelectTypes.skus ||
        (type === genericSelectTypes.editions && {
          menuList: () => ({
            textTransform: "capitalize",
          }),
          valueContainer: () => ({
            textTransform: "capitalize",
          }),
        })),
      multiValueRemove: (base) => {
        return base.data.isFixed ? { display: "none" } : {};
      },
      multiValueLabel: (base) => {
        return base.data.isFixed ? { paddingRight: "6px" } : {};
      },
    };
  }, [type]);

  const [value, setValue] = useState(initialValue);

  useEffect(() => {
    if (isMulti && value.length === options.length && options.length > 0) {
      selectRef.current.blur();
    }
  }, [isMulti, options?.length, value?.length]);

  const onChangeHandler = useCallback(
    (value, { action, removedValue }) => {
      if (isMulti) {
        // eslint-disable-next-line default-case
        switch (action) {
          case "remove-value":
          case "pop-value":
            if (removedValue.isFixed) {
              value = [...value, removedValue];
            }
            break;
          case "clear":
            value = options.filter((v) => v.isFixed);
            break;
        }
        setValue(orderOptions(value));
        formikProps?.setFieldValue(name, value);
      } else {
        setValue(value);
      }
      onChange?.(value);
    },
    [formikProps, isMulti, name, onChange, options]
  );

  useEffect(() => {
    setValue(getIn(formikProps.values, name));
  }, [formikProps.values, name]);

  return (
    <Select
      onChange={onChangeHandler}
      isMulti={isMulti}
      name={name}
      type="select"
      options={options}
      placeholder={placeholder}
      isInvalid={isInvalid}
      validationProps={formikProps}
      value={value}
      dataTestId={dataTestId}
      styles={styles}
      ref={selectRef}
      isClearable={isClearable || (isMulti && value.some((v) => !v.isFixed))}
      isDisabled={disabled}
      {...rest}
    />
  );
};

export default GenericOptionsSelect;
