import React, { useCallback, useMemo } from "react";
import { useDispatch } from "react-redux";
import { Page, Button, Dropdown } from "orion";
import { useParams } from "react-router-dom";
import MoreGreyOutline from "@druva-apps/orion-icons/lib/MoreGreyOutline";

import { genericErrorMessage } from "consts";
import { showAlert } from "slices/alertsSlice";
import useUnsuspendMSP from "queryHooks/useUnsuspendMSP/useUnsuspendMSP";
import { openModal } from "slices/modalsSlice";
import useMSP from "queryHooks/useMSP/useMSP";
import BackButton from "shared/BackButton/BackButton";

import Summary from "./Summary";

const tabBarItems = [
  {
    title: "Summary",
    id: "summary",
    component: Summary,
  },
];
const Account = () => {
  const dispatch = useDispatch();
  let { accountId } = useParams();

  const { data } = useMSP(accountId, {
    onError: (e) => {
      dispatch(
        showAlert({
          message: e.data?.message || genericErrorMessage,
          type: "danger",
        })
      );
    },
  });

  const dropdownOptions = useMemo(() => {
    if (data?.isSuspended) {
      return [{ label: "Enable" }];
    }
    return [{ label: "Suspend" }];
  }, [data]);

  const suspendHandler = useCallback(() => {
    dispatch(
      openModal({
        key: "SUSPEND_ACTION",
        mspId: accountId,
      })
    );
  }, [dispatch, accountId]);

  const { mutate: unsuspend } = useUnsuspendMSP(accountId, {
    onError: (e) => {
      dispatch(
        showAlert({
          message: e.data?.message || genericErrorMessage,
          type: "danger",
        })
      );
    },
    onSuccess: () => {
      dispatch(
        showAlert({
          message: "Successfully unsuspended MSP account.",
          type: "success",
        })
      );
    },
  });

  const onEditHandler = useCallback(() => {
    dispatch(
      openModal({
        key: "UPDATE_MSP_FORM",
        mspId: accountId,
        header: "Edit Summary",
      })
    );
  }, [accountId, dispatch]);

  const onOptionClickHandler = useCallback(
    ({ label }) => {
      switch (label) {
        case "Enable":
          unsuspend();
          break;
        case "Suspend":
          suspendHandler();
          break;
        default:
          break;
      }
    },
    [unsuspend, suspendHandler]
  );

  return (
    <Page.Main divider={false}>
      <Page.Header>
        <Page.Title>
          <div className="oui-d-flex oui-align-center">
            <BackButton to="/accounts" className="oui-mr-3" />
            <Page.TitleText>{data?.organizationName}</Page.TitleText>
          </div>
        </Page.Title>
        <Page.Actions>
          <Button
            className="oui-mr-3"
            type="secondary"
            label="Edit"
            onClick={onEditHandler}
          />
          <Dropdown
            items={dropdownOptions}
            size="md"
            type="secondary"
            icon={<MoreGreyOutline size="sm" clickable inheritFontColor />}
            onItemClick={onOptionClickHandler}
            rightAligned
          />
        </Page.Actions>
      </Page.Header>
      <Page.Body>{data && <Page.Tabs items={tabBarItems} />}</Page.Body>
    </Page.Main>
  );
};

export default Account;
