import React, { Fragment } from "react";
import { Page } from "orion";
import { Switch, Route, Redirect } from "react-router-dom";

import Navbar from "shared/Navbar";

import Accounts from "./Accounts/Accounts";
import Account from "./Account/Account";

const App = () => (
  <Fragment>
    <Navbar brandName="Managed Services Center Support Portal" />
    <Page>
      <Switch>
        <Route exact path="/accounts" component={Accounts} />
        <Route exact path="/accounts/:accountId" component={Account} />
        <Redirect to="/accounts" />
      </Switch>
    </Page>
  </Fragment>
);

export default App;
