import { useInfiniteQuery } from "react-query";
import { API_MSP_LIST_V2, PAGE_SIZE, QUERIES } from "consts";
import { useHttpClient } from "contexts/httpClient";

const fetchMsps = async ({ httpClient, pageParam }) => {
  const { mspList, nextPageToken } = await httpClient.get(API_MSP_LIST_V2, {
    params: pageParam ? { pageToken: pageParam } : { pageSize: PAGE_SIZE },
  });

  return Promise.resolve({
    data: mspList,
    pageParam: nextPageToken,
  });
};

export function useAllMSPListPages() {
  const httpClient = useHttpClient();
  return useInfiniteQuery(
    [QUERIES.getMspList],
    ({ pageParam = "" }) => fetchMsps({ httpClient, pageParam }),
    {
      select: (data) => {
        return data?.pages?.flatMap((page) => page.data) || [];
      },
      getNextPageParam: ({ pageParam }) => {
        return pageParam || undefined;
      },
      meta: {
        disableFullPageLoader: true,
      },
      staleTime: 1000 * 60 * 5,
    }
  );
}
