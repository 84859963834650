import { useQuery } from "react-query";
import { API_SERVICE_PLAN_WORKLOAD_LIST } from "consts";
import { useHttpClient } from "contexts/httpClient";

export default function useMspProducts(config) {
  const httpClient = useHttpClient();
  return useQuery(
    ["MspProducts"],
    () => {
      return httpClient.get(API_SERVICE_PLAN_WORKLOAD_LIST);
    },
    config
  );
}
