import { useCallback } from "react";
import { getIn } from "formik";
import { Card, Col, Input, Label, Row, ToggleButton } from "orion";
import { getIsInputVaild } from "components/Field";
import ModalForm from "shared/ModalForm";
import {
  Allowed_To_Delete,
  Allowed_To_Delete_Now,
  Allowed_To_Reset_Password,
  Allowed_To_Reset_Password_Admin_Email,
  getValidationSchema,
} from ".";

const UpdatePermissionForm = ({
  closeModal,
  initialVaue,
  isOpen,
  updatePermission,
}) => {
  const handleSubmit = useCallback(
    (model) => {
      const updatedDataModel = { ...model };

      if (!updatedDataModel?.[Allowed_To_Delete]) {
        updatedDataModel[Allowed_To_Delete_Now] = false;
      }

      if (!updatedDataModel?.[Allowed_To_Reset_Password]) {
        updatedDataModel[Allowed_To_Reset_Password_Admin_Email] = "";
      }

      updatePermission(updatedDataModel);
    },
    [updatePermission]
  );

  return (
    <ModalForm
      disableSubmitWhenInvalid
      closeModal={closeModal}
      withValidation
      header="Edit Advance Settings"
      isOpen={isOpen}
      size="lg"
      onSubmit={handleSubmit}
      initialValues={initialVaue}
      validationSchema={getValidationSchema}
      renderChildren={(props) => {
        return (
          <Card
            fullHeight
            title={() => <Card.Title collapsible title="Advance Settings" />}
            body={() => (
              <div className="oui-ml-1">
                <Row className="oui-mb-2 oui-mt-2">
                  <Col>
                    <ToggleButton
                      isActive={getIn(props.values, Allowed_To_Delete)}
                      name={Allowed_To_Delete}
                      onClick={props.setFieldValue}
                      label="Enable Delete Tenant"
                    />
                    <Label
                      text="Once enabled, MSP administrators will be able to delete all associated tenants."
                      className="oui-ml-4 oui-pl-3 font-12"
                      muted
                    />
                  </Col>
                </Row>
                {getIn(props.values, Allowed_To_Delete) && (
                  <Row>
                    <Col className="oui-pl-4 oui-pr-4">
                      <Row>
                        <Col className="oui-ml-4 oui-pl-4 oui-mb-2">
                          <Input
                            type="checkbox"
                            id={Allowed_To_Delete_Now}
                            name={Allowed_To_Delete_Now}
                            label="Allow Delete Immediately"
                            checked={getIn(props.values, Allowed_To_Delete_Now)}
                            value={getIn(props.values, Allowed_To_Delete_Now)}
                            onChange={({ target: { name, value } }) =>
                              props.setFieldValue(name, !value)
                            }
                          />
                        </Col>
                      </Row>
                      <Card
                        className="oui-ml-4 oui-mr-4 oui-mb-2 card-background-grey"
                        body={() => (
                          <>
                            <Row className="oui-mt-1">
                              <Col className="oui-text-muted font-12">
                                <ul className="oui-pl-4 oui-mb-0">
                                  <li>
                                    MSP administrator will delete the tenant
                                    permanently.
                                  </li>
                                  <li>
                                    All the associations and storages to this
                                    tenant cannot be recovered.
                                  </li>
                                  <li>
                                    Proceed with caution. Valid for 24 hours
                                    only.
                                  </li>
                                </ul>
                              </Col>
                            </Row>
                          </>
                        )}
                      />
                    </Col>
                  </Row>
                )}
                <Row className="oui-mb-2 oui-mt-4">
                  <Col>
                    <ToggleButton
                      isActive={getIn(props.values, Allowed_To_Reset_Password)}
                      name={Allowed_To_Reset_Password}
                      onClick={props.setFieldValue}
                      label="Enable Reset Password"
                    />
                    <Label
                      text="The email must belong to the MSP admin for sharing API access to reset password. The flag will be automatically disabled if:"
                      className="oui-ml-4 oui-pl-3 font-12 oui-mb-0"
                      muted
                    />
                    <Label
                      text="Reset Password API has been successfully used or the API has remained unused for 24-hours"
                      className="oui-ml-4 oui-pl-3 font-12"
                      muted
                    />
                  </Col>
                </Row>
                <Row className="oui-ml-4 oui-mb-4">
                  <Col sm={{ size: 2 }}>
                    <Label muted text={"Email ID"} />
                  </Col>
                  <Col sm={{ size: 5 }} className="oui-pl-0">
                    <Input
                      type="text"
                      name={Allowed_To_Reset_Password_Admin_Email}
                      id={Allowed_To_Reset_Password_Admin_Email}
                      validationProps={props}
                      isInvalid={getIsInputVaild(
                        props,
                        Allowed_To_Reset_Password_Admin_Email
                      )}
                      placeholder="Email to send password reset link"
                      disabled={!props.values.allowedToResetPassword}
                    />
                  </Col>
                </Row>
              </div>
            )}
          />
        );
      }}
      submitButtonLabel="Save"
    />
  );
};

export default UpdatePermissionForm;
