import React, { useState, useCallback, useMemo, useEffect } from "react";

import AccountListTable from "./AccountListTable";
import AccountListHeader from "./AccountListHeader";

import { useAllMSPListPages } from "queryHooks/useMSPList/useMSPList";
import uniqBy from "lodash/uniqBy";

const matchProductIDs = (msp, productIDs) => {
  return Array.isArray(productIDs) && productIDs.length > 0
    ? productIDs.includes(msp.productID)
    : true;
};

const matchSearchQuery = (msp, searchQuery) => {
  if (Boolean(searchQuery) && typeof searchQuery === "string") {
    return Boolean(
      msp.sfdcId?.toLowerCase()?.includes(searchQuery.toLowerCase()) ||
        msp.organizationName?.toLowerCase()?.includes(searchQuery.toLowerCase())
    );
  }
  return true;
};

const matchStorageRegions = (msp, regions) => {
  return Array.isArray(regions) && regions.length > 0
    ? msp.storageRegions.every((region) => regions.includes(region))
    : true;
};

const matchEdition = (msp, edition) => {
  return edition && edition.length > 0 ? msp.edition === edition : true;
};

const AccountList = () => {
  const [selected, setSelected] = useState();
  const [filters, setFilters] = useState({
    sortBy: "organizationName",
    sortOrder: "asc",
  });

  const { data, isFetching, hasNextPage, fetchNextPage } = useAllMSPListPages();

  const hasMorePages = hasNextPage === undefined || hasNextPage;

  useEffect(() => {
    if (!isFetching && hasNextPage) {
      fetchNextPage();
    }
  }, [isFetching, hasNextPage, fetchNextPage]);

  const mspList = useMemo(() => data || [], [data]);

  const wholeList = useMemo(() => {
    return (mspList || [])
      .map((msp) => {
        const { id, products, ...rest } = msp;
        return products?.map((product) => ({
          ...product,
          id: `${id}-${product.productID}`,
          mspId: msp.id,
          ...rest,
        }));
      })
      .flat();
  }, [mspList]);

  const filteredList = useMemo(() => {
    return wholeList.filter((msp) => {
      return (
        matchEdition(msp, filters.edition) &&
        matchProductIDs(msp, filters.productIDs) &&
        matchSearchQuery(msp, filters.search) &&
        matchStorageRegions(msp, filters.storageRegions)
      );
    });
  }, [wholeList, filters]);

  const sortedList = useMemo(() => {
    const order = {
      asc: 1,
      desc: -1,
    };
    const sortList = [...filteredList];
    sortList.sort((msp1, msp2) => {
      const field1 = msp1[filters.sortBy];
      const field2 = msp2[filters.sortBy];
      return field1.localeCompare(field2) * order[filters.sortOrder];
    });
    return sortList;
  }, [filteredList, filters.sortBy, filters.sortOrder]);

  useEffect(() => {
    if (wholeList && selected) {
      const updatedSelected = wholeList.find(({ id }) => {
        return id === selected.id;
      });

      if (updatedSelected) {
        const { isSuspended, id, isDeleted, mspId } = updatedSelected;
        if (isDeleted) {
          setSelected(null);
        } else if (
          isSuspended !== selected.isSuspended ||
          isDeleted !== selected.isDeleted
        ) {
          setSelected({
            id,
            mspId,
            isSuspended,
            isDeleted,
          });
        }
      } else {
        setSelected(null);
      }
    }
  }, [selected, wholeList]);

  const onRowClickHandler = useCallback(
    ({ id, mspId, isSuspended, isDeleted }) => {
      setSelected({ id, mspId, isSuspended, isDeleted });
    },
    []
  );

  const onSortHandler = useCallback((sortBy, sortOrder) => {
    setFilters((filters) => ({ ...filters, sortBy, sortOrder }));
  }, []);

  const onSearchHandler = useCallback((search) => {
    setFilters((filters) => ({ ...filters, search }));
  }, []);

  const onFiltersChangeHandler = useCallback((filtersFromPopover) => {
    const popoverFilters = Object.entries(filtersFromPopover).reduce(
      (prev, [key, rawValue]) => {
        return {
          ...prev,
          [key]: Array.isArray(rawValue)
            ? rawValue.map((obj) => obj?.value)
            : rawValue?.value
            ? rawValue.value
            : rawValue
            ? rawValue
            : undefined,
        };
      },
      {}
    );
    const filterNames = Object.entries(popoverFilters).reduce(
      (res, [key, value]) => {
        if (value && value.length !== 0) {
          res.push(key);
        }
        return res;
      },
      []
    );
    setFilters((filters) => ({
      ...filters,
      ...popoverFilters,
      filters: filterNames,
    }));
  }, []);

  return (
    <>
      <AccountListHeader
        selected={selected}
        onSearch={onSearchHandler}
        onFiltersChange={onFiltersChangeHandler}
      />
      <AccountListTable
        isLoading={isFetching}
        dataList={sortedList}
        selected={selected}
        onRowClick={onRowClickHandler}
        sortBy={filters.sortBy}
        sortOrder={filters.sortOrder}
        onSort={onSortHandler}
        totalCount={uniqBy(sortedList, "mspId").length}
        isLoadingMore={hasMorePages}
      />
    </>
  );
};

export default AccountList;
