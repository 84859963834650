import { configureStore } from "@reduxjs/toolkit";
import fullPageLoader from "slices/fullPageLoaderSlice";
import modals from "slices/modalsSlice";
import alerts from "slices/alertsSlice";

export default configureStore({
  preloadedState: {},
  reducer: {
    fullPageLoader,
    modals,
    alerts,
  },
});
