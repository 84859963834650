import React, { useCallback } from "react";
import { useDispatch } from "react-redux";
import { addDays, endOfDay, isBefore } from "date-fns";
import { asUTC } from "utils/timezone";
import { dataSourceIdToDSName, genericErrorMessage, PRODUCT_ID } from "consts";
import { showAlert } from "slices/alertsSlice";
import ModalForm from "shared/ModalForm";

import FormContainer from "./FormContainer";
import useUpdateMSPForm from "./useUpdateMSPForm";

const validationSchemaCreator = (Yup) => {
  return Yup.object().shape({
    organizationName: Yup.string().max(30, "Max 30 chars").required("Required"),
    sfdcId: Yup.string()
      .required("Required")
      .matches(/^[a-z0-9]+$/i, {
        excludeEmptyString: true,
        message: "Invalid SFDC ID. Only alphanumeric characters allowed.",
      })
      .test(
        "start",
        "Must start with '001'",
        (val) => (val || "").substr(0, 3) === "001"
      )
      .test(
        "length",
        "Must be exactly 15 or 18 characters",
        (val) => (val || "").length === 15 || (val || "").length === 18
      ),
    edition: Yup.mixed().required("Required"),
    timezone: Yup.mixed().required("Required"),
    expiryDate: Yup.date()
      .nullable()
      .required("Required")
      .test(
        "edition",
        "You can evaluate for a maximum of 30 days",
        function (value) {
          const { edition } = this.parent;
          if (edition?.value === "evaluation") {
            return isBefore(value, addDays(endOfDay(new Date()), 31));
          }
          return true;
        }
      ),
    products: Yup.array()
      .of(
        Yup.object().shape({
          skus: Yup.array().test("enabled", "Required", function (value) {
            const { enabled } = this.parent;
            if (enabled) {
              return value.length > 0;
            }
            return true;
          }),
          storageRegions: Yup.array().test(
            "enabled",
            "Required",
            function (value) {
              const { enabled } = this.parent;
              if (enabled) {
                return value.length > 0;
              }
              return true;
            }
          ),
          datasources: Yup.array().test(
            "enabled",
            "Required",
            function (value) {
              const { enabled, productID } = this.parent;
              if (enabled && productID === PRODUCT_ID.saasAppsAndEndpoints) {
                return value.length > 0;
              }
              return true;
            }
          ),
        })
      )
      .test("products", function (value) {
        return value.some((v) => v.enabled);
      }),
  });
};

const MSPForm = ({ closeModal, header, isOpen, mspId }) => {
  const dispatch = useDispatch();

  const [initialValues, { mutate }] = useUpdateMSPForm(mspId);

  const onSubmitHandler = useCallback(
    (
      { expiryDate, organizationName, edition, sfdcId, timezone, products },
      formik
    ) => {
      mutate(
        {
          organizationName,
          sfdcId,
          edition: edition?.value,
          timezone: timezone?.value,
          expiryDate: asUTC(endOfDay(expiryDate)).toISOString(),
          products: products
            ?.filter(({ enabled }) => enabled)
            .map(({ productID, datasources, skus, storageRegions }) => {
              let selectedDatasource =
                productID === PRODUCT_ID.hybridWorkloads
                  ? [
                      {
                        name: "Hybrid Workloads",
                        isEnabled: true,
                      },
                    ]
                  : datasources.map((datasource) => ({
                      name: dataSourceIdToDSName[datasource.value],
                      isEnabled: true,
                    }));
              if (productID === PRODUCT_ID.saasAppsAndEndpoints) {
                const initialDataSource = initialValues.products?.find(
                  (product) =>
                    product.productID === PRODUCT_ID.saasAppsAndEndpoints
                )?.datasources;

                selectedDatasource = [
                  ...selectedDatasource,
                  ...(initialDataSource || [])
                    .filter(
                      (datasource) =>
                        !selectedDatasource.some(
                          (ds) =>
                            ds.name === dataSourceIdToDSName[datasource.value]
                        )
                    )
                    ?.map((ds) => ({
                      name: dataSourceIdToDSName[ds.value],
                      isEnabled: false,
                    })),
                ];
              }

              return {
                productID,
                skus: skus.map(({ value }) => value),
                storageRegions: storageRegions.map(({ value }) => value),
                datasources: selectedDatasource,
              };
            }),
        },
        {
          onError: (e) => {
            if (e.status === 411) {
              // TODO update after initialErrors props will be handled by Form
              formik.setErrors(e.data);
            } else {
              dispatch(
                showAlert({
                  message: e.data?.message || genericErrorMessage,
                  type: "danger",
                })
              );
            }
          },
          onSuccess: (data) => {
            if (data?.warning) {
              dispatch(
                showAlert({
                  message: data?.message,
                  type: "warning",
                })
              );
            } else {
              dispatch(
                showAlert({
                  message: "MSP account edited successfully",
                  type: "success",
                })
              );
            }
            closeModal();
          },
        }
      );
    },
    [mutate, dispatch, closeModal, initialValues]
  );
  return initialValues ? (
    <ModalForm
      disableSubmitWhenInvalid
      closeModal={closeModal}
      withValidation
      header={header}
      isOpen={isOpen}
      size="md"
      onSubmit={onSubmitHandler}
      initialValues={initialValues}
      validationSchema={validationSchemaCreator}
      renderChildren={(props) => <FormContainer formikProps={props} isEdit />}
      submitButtonLabel="Save"
    />
  ) : null;
};

export default MSPForm;
