import _debounce from "lodash.debounce";

import { API_REFRESH_TOKEN } from "consts";

const refreshToken = (client, refreshToken) => {
  return client.post(API_REFRESH_TOKEN, {
    refresh_token: refreshToken,
  });
};
export default _debounce(refreshToken, 10000, {
  leading: true,
  trailing: false,
});
