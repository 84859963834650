import { useQuery } from "react-query";
import { API_MSP_V2 } from "consts";
import { useHttpClient } from "contexts/httpClient";
import withUrlParams from "utils/withUrlParams";

export default function useMSP(mspId, config) {
  const httpClient = useHttpClient();
  return useQuery(
    ["MSP", mspId],
    () => {
      return httpClient.get(withUrlParams(API_MSP_V2, { mspId }));
    },
    config
  );
}
