import React, { cloneElement } from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import { modals as orionModals } from "slices/modalsSlice";
import { closeModal } from "slices/modalsSlice";

const SimpleModalRenderer = ({ components }) => {
  const modals = useSelector(orionModals);
  const dispatch = useDispatch();
  return modals.map((modal) => {
    const { key, ...other } = modal;
    const Component = components[key];
    if (!Component) {
      return null;
    }
    return cloneElement(<Component />, {
      closeModal: () => dispatch(closeModal(key)),
      key,
      ...other,
    });
  });
};

SimpleModalRenderer.propTypes = {
  components: PropTypes.object,
};

export default SimpleModalRenderer;
