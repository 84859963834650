import { useMutation, useQueryClient } from "react-query";
import { API_MSP_SUSPEND, QUERIES } from "consts";
import { useHttpClient } from "contexts/httpClient";
import withUrlParams from "utils/withUrlParams";

export default function useSuspendMSP(mspId, config) {
  const httpClient = useHttpClient();
  const queryClient = useQueryClient();
  return useMutation(
    () => httpClient.post(withUrlParams(API_MSP_SUSPEND, { mspId })),
    {
      ...config,
      onSuccess: (data) => {
        queryClient.resetQueries(QUERIES.getMspList);
        queryClient.invalidateQueries("MSP");
        config?.onSuccess?.(data);
      },
    }
  );
}
