import { createSlice } from "@reduxjs/toolkit";

const modalsSlice = createSlice({
  name: "modals",
  initialState: {
    modals: [],
  },
  reducers: {
    openModal(state, { payload }) {
      state.modals.push(payload);
    },
    closeModal(state, { payload }) {
      state.modals = state.modals.filter((modal) => modal.key !== payload);
    },
    closeAllModals(state) {
      state.modals = [];
    },
  },
});

export const { openModal, closeModal, closeAllModals } = modalsSlice.actions;

export const modals = (state) => state.modals.modals;

export default modalsSlice.reducer;
