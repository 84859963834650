import React, { useCallback, useState } from "react";
import { Card, Row, Col, ToggleButton, Heading } from "orion";
import { endOfDay, addDays } from "date-fns";
import { FieldArray } from "formik";

import Field from "components/Field";
import {
  TimezoneOptions,
  genericSelectTypes,
  PRODUCT_ID,
  productIDToName,
} from "consts";

const FormContainer = (props) => {
  const getEodMoment = useCallback((nativeDate) => {
    return endOfDay(nativeDate);
  }, []);

  const [licenseExpiryMaxDate, setLicenseExpiryMaxDate] = useState();

  const onLicenseTypeChangeHandler = useCallback(
    ({ value }) => {
      if (value === "evaluation") {
        const maxDate = addDays(endOfDay(new Date()), 30);
        if (!props.values.expiryDate) {
          props.setFieldValue("expiryDate", maxDate);
        }
        setLicenseExpiryMaxDate(maxDate);
      } else {
        setLicenseExpiryMaxDate(undefined);
      }
    },
    [props]
  );

  return (
    <>
      <Card
        className="mt-2"
        title="General"
        body={() => {
          return (
            <>
              <Field
                label="Organization Name"
                name="organizationName"
                dataTestId="sportal-add-msp-org-name"
                formikProps={props}
                placeholder="Enter Organization Name"
              />
              <Field
                label="SFDC ID"
                name="sfdcId"
                dataTestId="sportal-add-msp-sfdc-id"
                formikProps={props}
                placeholder="Enter SFDC ID"
                tooltip="SFDC ID should start with '001' and be 15 or 18 characters long"
              />
              <Field
                label="First Name"
                name="user.firstName"
                dataTestId="sportal-add-msp-first-name"
                formikProps={props}
                placeholder="Enter First Name"
              />
              <Field
                label="Last Name"
                name="user.lastName"
                dataTestId="sportal-add-msp-last-name"
                formikProps={props}
                placeholder="Enter Last Name"
              />
              <Field
                label="Work Email"
                name="user.email"
                dataTestId="sportal-add-msp-work-email"
                tooltip="Enter a work email address to add an MSP. Personal email addresses are not supported."
                tooltipProps={{ dataTestId: "sportal-add-msp-email-info" }}
                formikProps={props}
                placeholder="Enter Email"
              />
              <Field
                type="phone"
                label="Phone"
                name="user.phone"
                dataTestId="sportal-add-msp-phone"
                formikProps={props}
                placeholder="Enter Number"
              />
              <Field
                type="select"
                options={TimezoneOptions}
                name="timezone"
                label="Timezone"
                placeholder="Select"
                dataTestId="sportal-add-msp-timezone-drpdwn"
                formikProps={props}
              />
              <Field
                type={genericSelectTypes.editions}
                name="edition"
                label="License Type"
                placeholder="Select License Type"
                dataTestId="sportal-add-msp-edition-drpdwn"
                formikProps={props}
                onChange={onLicenseTypeChangeHandler}
                downgradeAllowed={true}
              />
              <Field
                type="date"
                name="expiryDate"
                label="Expiry Date"
                placeholder="Select Date"
                dataTestId="sportal-add-msp-expiry-date"
                formikProps={props}
                interceptor={getEodMoment}
                maxDate={licenseExpiryMaxDate}
              />
            </>
          );
        }}
      />
      <FieldArray
        name="products"
        render={(_) => {
          return (
            <>
              {props.values.products.length > 0 &&
                props.values.products.map((detail, index) => (
                  <Card
                    className="mt-4"
                    key={`products-${detail.productID}`}
                    body={() => {
                      const isEnabled = props.values.products[index].enabled;

                      // Disabled Business if only product selected is Endpoints
                      const dataSources =
                        props.values?.products[index]?.datasources;
                      const disabledEditions =
                        dataSources?.length > 0 &&
                        dataSources.every((sku) => sku.value === 3)
                          ? ["business"]
                          : [];

                      return (
                        <>
                          <Row className="oui-mb-4 oui-mt-1">
                            <Col>
                              <Heading
                                text={productIDToName[detail.productID]}
                                inline
                              />
                              <ToggleButton
                                isActive={isEnabled}
                                name={`products[${index}].enabled`}
                                onClick={(_, isActive) => {
                                  props.setFieldValue(
                                    `products[${index}].enabled`,
                                    isActive
                                  );
                                }}
                                validationProps={props}
                                className="oui-d-inline oui-ml-3 oui-align-middle"
                                dataTestId={`sportal-add-msp-toggle-${index}`}
                              />
                              <div className="font-12 oui-text-muted oui-mt-1">
                                {`${productIDToName[detail.productID]} is ${
                                  isEnabled ? "enabled" : "disabled"
                                }`}
                              </div>
                            </Col>
                          </Row>
                          {isEnabled && (
                            <>
                              <Field
                                type={genericSelectTypes.skus}
                                isMulti
                                name={`products[${index}].skus`}
                                label="Edition"
                                placeholder="Select"
                                dataTestId={`sportal-add-msp-sku-drpdwn-${index}`}
                                formikProps={props}
                                disabledEditions={disabledEditions}
                                onChange={(value) => {
                                  props.setFieldValue(
                                    `products[${index}].skus`,
                                    value
                                  );
                                  if (
                                    value?.length > 0 &&
                                    !value?.some(
                                      (sku) =>
                                        sku.value === "elite" ||
                                        sku.value === "enterprise"
                                    )
                                  ) {
                                    const datasource = (
                                      props.values?.products?.[index]
                                        ?.datasources || []
                                    ).filter((ds) => ds.value !== 3);
                                    props.setFieldValue(
                                      `products[${index}].datasources`,
                                      datasource
                                    );
                                  }
                                }}
                              />
                              <Field
                                type={genericSelectTypes.storageRegions}
                                isMulti
                                name={`products[${index}].storageRegions`}
                                label="Storage Region"
                                placeholder="Select Region"
                                dataTestId="sportal-add-msp-storage-region-drpdwn-hybrid-workload"
                                formikProps={props}
                                productId={detail.productID}
                              />
                              {detail.productID ===
                              PRODUCT_ID.saasAppsAndEndpoints ? (
                                <Field
                                  type={genericSelectTypes.datasources}
                                  isMulti
                                  name={`products[${index}].datasources`}
                                  label="Product"
                                  placeholder="Select"
                                  dataTestId={`sportal-add-msp-product-drpdwn-${detail.productID}`}
                                  formikProps={props}
                                  productId={detail.productID}
                                  tooltip="Business Edition not supported for Endpoints"
                                  isEndpointAllowedForEdition={(
                                    props.values?.products[index]?.skus || []
                                  ).some(
                                    (sku) =>
                                      sku.value === "elite" ||
                                      sku.value === "enterprise"
                                  )}
                                  onChange={(value) => {
                                    props.setFieldValue(
                                      `products[${index}].datasources`,
                                      value
                                    );
                                    if (
                                      value?.length === 1 &&
                                      value[0].value === 3
                                    ) {
                                      const skus = (
                                        props.values?.products[index].skus || []
                                      ).filter(
                                        (sku) => sku.value !== "business"
                                      );
                                      props.setFieldValue(
                                        `products[${index}].skus`,
                                        skus
                                      );
                                    }
                                  }}
                                />
                              ) : null}
                            </>
                          )}
                        </>
                      );
                    }}
                  />
                ))}
            </>
          );
        }}
      />
    </>
  );
};

export default FormContainer;
