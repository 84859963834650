import React, { useCallback, useMemo, useState } from "react";
import { Button, Input, Dropdown, FilterButton, Row, Col } from "orion";
import _debounce from "lodash.debounce";
import { MoreGreyOutline } from "@druva-apps/orion-icons";
import { useDispatch } from "react-redux";

import { genericErrorMessage, QUERIES } from "consts";
import { showAlert } from "slices/alertsSlice";
import useUnsuspendMSP from "queryHooks/useUnsuspendMSP/useUnsuspendMSP";
import { openModal } from "slices/modalsSlice";

import AccountFilters, { filters } from "./AccountFilters";
import styles from "./AccountListHeader.module.scss";
import { useQueryClient } from "react-query";

const AccountListHeader = ({ selected, onSearch, onFiltersChange }) => {
  const dispatch = useDispatch();

  const queryClient = useQueryClient();

  const dropdownOptions = useMemo(
    () => [
      {
        label: "Enable",
        disabled: !selected || !selected?.isSuspended || selected?.isDeleted,
      },
    ],
    [selected]
  );
  const debouncedHandler = useMemo(() => _debounce(onSearch, 300), [onSearch]);
  const [searchInputValue, setSearchInputValue] = useState("");
  const onSearchHandler = useCallback(
    (e) => {
      setSearchInputValue(e?.target?.value || "");
      debouncedHandler(e?.target?.value || "");
    },
    [debouncedHandler]
  );

  const suspendHandler = useCallback(() => {
    dispatch(
      openModal({
        key: "SUSPEND_ACTION",
        mspId: selected.mspId,
        refetchOnSuccess: QUERIES.getMspList,
      })
    );
  }, [dispatch, selected?.mspId]);

  const { mutate: unsuspend } = useUnsuspendMSP(selected?.mspId, {
    onError: (e) => {
      queryClient.refetchQueries(QUERIES.getMspList);
      dispatch(
        showAlert({
          message: e.data?.message || genericErrorMessage,
          type: "danger",
        })
      );
    },
    onSuccess: () => {
      queryClient.refetchQueries(QUERIES.getMspList);
      dispatch(
        showAlert({
          message: "Successfully unsuspended MSP account.",
          type: "success",
        })
      );
    },
  });

  const onOptionClickHandler = useCallback(
    ({ label }) => {
      if (label === "Enable") {
        unsuspend();
      }
    },
    [unsuspend]
  );

  return (
    <Row className={styles.listHeader}>
      <Col sm={4}>
        <Button
          label="Suspend"
          disabled={!selected || selected.isSuspended}
          onClick={suspendHandler}
          className="oui-mr-3"
        />
        <Dropdown
          items={dropdownOptions}
          size="md"
          type="secondary"
          onItemClick={onOptionClickHandler}
          icon={<MoreGreyOutline size="sm" clickable inheritFontColor />}
        />
      </Col>
      <Col sm={8} className="oui-d-flex oui-justify-content-end">
        <div>
          <Input
            onChange={onSearchHandler}
            className={styles.searchInput}
            type="search"
            placeholder="Search by Organization Name or SFDC ID "
            value={searchInputValue}
            showClearIcon={!!searchInputValue}
            onClear={onSearchHandler}
          />
        </div>
        <FilterButton
          filterTitle="Filters"
          popoverClassName="oui-p-1 oui-pb-3"
          className="oui-ml-3"
          filters={filters}
          renderFilters={AccountFilters}
          onSubmit={onFiltersChange}
        />
      </Col>
    </Row>
  );
};

export default AccountListHeader;
