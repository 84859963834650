import React, { useMemo } from "react";
import { Link } from "react-router-dom";
import DataCollection from "orion/lib/dataCollection";
import MessageBox from "orion/lib/messageBox";
import Tooltip from "orion/lib/tooltip";
import Bouncer from "orion/lib/bouncer";
import { asLocalTime } from "utils/timezone";
import noMSPSVG from "assets/images/noMSP.svg";
import {
  COLOR_DANGER,
  COLOR_WARNING,
  dateFormatter,
  productIDToName,
} from "consts";
import {
  InactiveGreyOutline,
  DeleteGreyOutline,
} from "@druva-apps/orion-icons";
import CellRenderer from "./CellRenderer";

const columnsDef = [
  {
    dataKey: "organizationName",
    key: "organizationName",
    title: "Organization Name",
    sortable: true,
    flexGrow: 2,
    resizable: true,
    getCsvValue: ({ cellData }) => cellData,
    cellRenderer: (cellData, dataKey, rowIndex, rowData) => {
      const { isDeleted, isSuspended, mspId, deleteDate } = rowData;
      return (
        <Tooltip text={cellData} overflowOnly>
          <span
            className={`oui-btn-label oui-text-truncate ${
              isSuspended || isDeleted ? "oui-text-muted" : ""
            }`}
          >
            <Link to={`/accounts/${mspId}`}>
              <span className="oui-pr-2">{cellData}</span>
            </Link>
            {isDeleted ? (
              <Tooltip
                text={`Data is scheduled to be deleted on ${dateFormatter.format(
                  new Date(deleteDate)
                )}`}
                placement="bottom"
              >
                <span style={{ color: COLOR_DANGER }}>
                  <DeleteGreyOutline className="oui-mb-1" inheritFontColor />
                </span>
              </Tooltip>
            ) : isSuspended ? (
              <Tooltip text="MSP is suspended" placement="bottom">
                <span style={{ color: COLOR_WARNING }}>
                  <InactiveGreyOutline className="oui-mb-1" inheritFontColor />
                </span>
              </Tooltip>
            ) : null}
          </span>
        </Tooltip>
      );
    },
  },
  {
    dataKey: "sfdcId",
    key: "sfdcId",
    title: "SFDC ID",
    flexGrow: 2,
    sortable: false,
    resizable: true,
    cellRenderer: (cellData, dataKey, rowIndex, rowData) => {
      const { isDeleted, isSuspended } = rowData;
      return (
        <CellRenderer
          text={cellData}
          isDeleted={isDeleted}
          isSuspended={isSuspended}
        />
      );
    },
  },
  {
    dataKey: "productID",
    key: "productID",
    title: "Product",
    flexGrow: 2,
    sortable: false,
    resizable: true,
    cellRenderer: (cellData, dataKey, rowIndex, rowData) => {
      const { isDeleted, isSuspended } = rowData;
      return (
        <CellRenderer
          text={productIDToName[cellData]}
          isDeleted={isDeleted}
          isSuspended={isSuspended}
        />
      );
    },
  },
  {
    dataKey: "skus",
    key: "skus",
    title: "Edition",
    flexGrow: 2,
    resizable: true,
    togglable: true,
    cellRenderer: (cellData, dataKey, rowIndex, rowData) => {
      const { isDeleted, isSuspended } = rowData;
      const skus = cellData.join(", ");
      return (
        <CellRenderer
          text={skus}
          isDeleted={isDeleted}
          isSuspended={isSuspended}
        />
      );
    },
  },
  {
    dataKey: "edition",
    key: "edition",
    title: "License Type",
    flexGrow: 2,
    resizable: true,
    togglable: true,
    cellRenderer: (cellData, dataKey, rowIndex, rowData) => {
      const { isSuspended, isDeleted } = rowData;
      return (
        <CellRenderer
          text={cellData}
          isDeleted={isDeleted}
          isSuspended={isSuspended}
        />
      );
    },
  },
  {
    dataKey: "storageRegions",
    key: "storageRegions",
    title: "Storage Region",
    flexGrow: 2,
    resizable: true,
    togglable: true,
    cellRenderer: (cellData, dataKey, rowIndex, rowData) => {
      const { isDeleted, isSuspended } = rowData;
      const storageRegions = cellData.join(", ");
      return (
        <CellRenderer
          text={storageRegions}
          isDeleted={isDeleted}
          isSuspended={isSuspended}
        />
      );
    },
  },
  {
    dataKey: "expiryDate",
    key: "expiryDate",
    title: "Expiry",
    flexGrow: 2,
    resizable: true,
    togglable: true,
    cellRenderer: (cellData, dataKey, rowIndex, rowData) => {
      const { isSuspended, isDeleted } = rowData;
      return (
        <CellRenderer
          text={dateFormatter.format(asLocalTime(cellData))}
          isDeleted={isDeleted}
          isSuspended={isSuspended}
        />
      );
    },
  },
];

const AccountListTable = ({
  onRowClick,
  dataList,
  selected,
  sortBy = "-",
  sortOrder,
  onSort,
  isLoading,
  totalCount,
  isLoadingMore,
}) => {
  const height = useMemo(
    () => (dataList.length > 0 ? "calc(100vh - 236px)" : "400px"),
    [dataList.length]
  );
  const newData =
    dataList &&
    dataList.reduce((obj, value) => {
      let key = `${value.organizationName}`;
      if (obj[key] == null) {
        obj[key] = [];
      }
      obj[key].push(value);

      return obj;
    }, {});

  let acc = [];
  newData &&
    Object.keys(newData).forEach((obj) => {
      acc.push(newData[obj].length);
    });

  const rowSpanArr = acc;
  let rowSpanIndex = 1;
  columnsDef[rowSpanIndex].rowSpan = ({ rowIndex }) => {
    let acc = 0;
    for (let [index, x] of rowSpanArr.entries()) {
      if (acc === rowIndex) {
        acc = acc + x;
        return rowSpanArr[index];
      }
      acc = acc + x;
    }
  };

  const rowDetailRenderer = (rowData, cells, rowIndex) => {
    const rowSpan = columnsDef[rowSpanIndex].rowSpan({ rowData, rowIndex });

    if (rowSpan === 1) {
      const cell = cells[rowSpanIndex];
      let style = {
        ...cell.props.style,
        borderRight: "1px solid var(--table-row-border-bottom-color2)",
      };
      cells[1] = React.cloneElement(cell, { style });
    }

    if (rowSpan > 1) {
      const cell = cells[1];
      const cell0 = cells[0];
      const style = {
        ...cell.props.style,
        backgroundColor: "#FFFFFF",
        height: rowSpan * 50,
        alignSelf: "flex-start",
        borderBottom: "1px solid #dee2e6",
        zIndex: 4,
        pointerEvents: "visible !important",
        borderRight: "1px solid var(--table-row-border-bottom-color2)",
      };

      cells[0] = React.cloneElement(cell0, {
        style: {
          ...cells[0].props.style,
          height: rowSpan * 50,
          backgroundColor: "#FFFFFF",
          alignSelf: "flex-start",
          borderBottom: "1px solid #dee2e6",
          zIndex: 2,
          pointerEvents: "visible",
          cursor: "pointer",
        },
      });
      cells[1] = React.cloneElement(cell, { style });
    }
    return cells;
  };

  const disabledRows = useMemo(() => {
    return dataList.reduce((acc, { isDeleted, id }) => {
      if (isDeleted) {
        acc.push(id);
      }
      return acc;
    }, []);
  }, [dataList]);

  return (
    <div>
      <DataCollection
        dataGridConfig={{
          rowRenderer: rowDetailRenderer,
          columnsDef: columnsDef,
          enableRowSpan: true,
          data: dataList,
          onSort: onSort,
          sortBy: sortBy,
          sortOrder: sortOrder,
          paginationEnabled: false,
          tableId: "msp-list-table",
          responsive: true,
          tableHeight: height,
          uniqueDataKey: "id",
          isSelectable: true,
          multiSelect: false,
          disabledRows: disabledRows,
          totalRowsCount: totalCount,
          loadingMoreText: "Loading...",
          footerHeight: 0,
          enableConfiguration: true,
          enableFullScreen: false,
          enableExportToCsv: true,
          csvFileName: "MSP Accounts",
          noCheckboxTable: true,
          isLoadingMore: isLoadingMore,
          onRowClick: onRowClick,
          selectedRows: [selected?.id],
          emptyRenderer: () =>
            isLoadingMore ? null : (
              <MessageBox
                size="md"
                icon={{
                  src: noMSPSVG,
                  muted: false,
                }}
                primaryText="No MSP added"
              />
            ),
          renderCustomListCountWrapper: () => (
            <div className="oui-overflow-hidden">
              Accounts
              <span className="oui-dg-list-wrapper-data-separator"></span>
              {isLoadingMore ? (
                <Bouncer
                  size="md"
                  className="oui-d-inline-block oui-ml-1"
                  gray
                />
              ) : (
                totalCount
              )}
            </div>
          ),
        }}
        listCountWrapperConfig={{
          showFetchedRowsCount: false,
          listCountWrapperText: "Accounts",
        }}
      />
    </div>
  );
};

AccountListTable.defaultProps = {
  dataList: [],
  selected: {},
};

export default AccountListTable;
