import React, { useState } from "react";
import { Page, Button } from "orion";
import AccountList from "./components/accountList/AccountList";
import AddMSPWizard from "./components/addMSPWizard/AddMSPWizard";

const Accounts = () => {
  const [wizardIsOpen, setWizardIsOpen] = useState(false);
  return (
    <Page.Main>
      <Page.Header>
        <Page.Title>
          <Page.TitleText>MSP Accounts</Page.TitleText>
        </Page.Title>
        <Page.Actions>
          <Button
            type="primary"
            label="Add MSP"
            onClick={() => setWizardIsOpen(true)}
          />
        </Page.Actions>
      </Page.Header>
      <Page.Body>
        {wizardIsOpen && (
          <AddMSPWizard
            toggle={() => {
              setWizardIsOpen(false);
            }}
          />
        )}
        <AccountList />
      </Page.Body>
    </Page.Main>
  );
};

export default Accounts;
