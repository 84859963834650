import { useMemo } from "react";
import { useDispatch } from "react-redux";

import {
  dataSourceIdToName,
  dataSourceTypesToID,
  genericErrorMessage,
  PRODUCT_ID,
} from "consts";
import { showAlert } from "slices/alertsSlice";
import { closeModal } from "slices/modalsSlice";
import useUpdateMSP from "queryHooks/useUpdateMSP/useUpdateMSP";
import useMSP from "queryHooks/useMSP/useMSP";
import useStorageRegionList from "queryHooks/useStorageRegionList/useStorageRegionList";
import useEditionList from "queryHooks/useEditionList/useEditionList";
import useSkuList from "queryHooks/useSkuList/useSkuList";
import useProductDatasource from "queryHooks/useMspProducts/useProductDatasource";
import { asLocalTime } from "utils/timezone";

const productsInitialValues = [
  {
    enabled: false,
    productID: PRODUCT_ID.hybridWorkloads,
    skus: [],
    storageRegions: [],
    datasources: [],
  },
  {
    enabled: false,
    productID: PRODUCT_ID.saasAppsAndEndpoints,
    skus: [],
    storageRegions: [],
    datasources: [],
  },
];

const useUpdateMSPForm = (mspId) => {
  const dispatch = useDispatch();

  const queryConfig = useMemo(
    () => ({
      onError: (e) => {
        dispatch(closeModal("UPDATE_MSP_FORM"));
        dispatch(
          showAlert({
            message: e.data?.message || genericErrorMessage,
            type: "danger",
          })
        );
      },
    }),
    [dispatch]
  );

  const { data: mspData } = useMSP(mspId, queryConfig);
  const { data: storageRegionListData } = useStorageRegionList(queryConfig);
  const { data: skuListData } = useSkuList(queryConfig);
  const { data: editionListData } = useEditionList(queryConfig);
  const { data: saasAppsAndEndpointsDatasource } = useProductDatasource({
    productId: PRODUCT_ID.saasAppsAndEndpoints,
  });

  const useMutation = useUpdateMSP(mspId);

  const initialValues = useMemo(() => {
    if (
      mspData &&
      storageRegionListData &&
      skuListData &&
      editionListData &&
      saasAppsAndEndpointsDatasource
    ) {
      const hybridWorkloadDetails = mspData.products.filter(
        (details) => details.productID === PRODUCT_ID.hybridWorkloads
      )[0];
      if (hybridWorkloadDetails) {
        hybridWorkloadDetails.enabled = true;
        hybridWorkloadDetails.productID = PRODUCT_ID.hybridWorkloads;
        hybridWorkloadDetails.skus = skuListData.list
          .filter(({ id }) => {
            return hybridWorkloadDetails.skus.some((sku) => sku === id);
          })
          .map(({ name, id }) => ({
            label: name,
            value: id,
          }));
        const hybridWorkloadRegions = (storageRegionListData || []).filter(
          (d) => d.productID === PRODUCT_ID.hybridWorkloads
        )[0];
        hybridWorkloadDetails.storageRegions = hybridWorkloadRegions.regions
          .filter((regionName) =>
            hybridWorkloadDetails.storageRegions.includes(regionName)
          )
          .map((regionName) => ({
            label: regionName,
            value: regionName,
            isFixed: true,
          }));

        hybridWorkloadDetails.datasources = [
          {
            name: "Hybrid Workloads",
            isEnabled: true,
          },
        ];
      }
      const saasAppsAndEndpointsDetails = mspData.products.filter(
        (details) => details.productID === PRODUCT_ID.saasAppsAndEndpoints
      )[0];
      if (saasAppsAndEndpointsDetails) {
        saasAppsAndEndpointsDetails.enabled = true;
        saasAppsAndEndpointsDetails.skus = skuListData.list
          .filter(({ id }) => {
            return saasAppsAndEndpointsDetails.skus.some((sku) => sku === id);
          })
          .map(({ name, id }) => ({ label: name, value: id }));
        const saasAppsAndEndpointsRegions = (
          storageRegionListData || []
        ).filter((d) => d.productID === PRODUCT_ID.saasAppsAndEndpoints)[0];
        saasAppsAndEndpointsDetails.storageRegions =
          saasAppsAndEndpointsRegions.regions
            .filter((regionName) =>
              saasAppsAndEndpointsDetails.storageRegions.includes(regionName)
            )
            .map((regionName) => ({
              label: regionName,
              value: regionName,
              isFixed: true,
            }));

        saasAppsAndEndpointsDetails.datasources = saasAppsAndEndpointsDatasource
          .filter((datasource) =>
            saasAppsAndEndpointsDetails?.datasources?.some(
              (ds) =>
                dataSourceTypesToID[ds.name] === datasource.datasourceID &&
                ds.isEnabled
            )
          )
          .map((datasource) => ({
            value: datasource.datasourceID,
            label: dataSourceIdToName[datasource.datasourceID],
          }));
      }
      const edition = editionListData.list.find(
        ({ id }) => id === mspData.edition
      );

      return {
        organizationName: mspData.organizationName,
        email: mspData.email,
        sfdcId: mspData.sfdcId,
        edition: {
          label: edition.name,
          value: edition.id,
          isDisabled: false,
          isFixed: false,
        },
        timezone: { label: mspData.timezone, value: mspData.timezone },
        expiryDate: asLocalTime(mspData.expiryDate),
        products: [
          hybridWorkloadDetails || productsInitialValues[0],
          saasAppsAndEndpointsDetails || productsInitialValues[1],
        ],
      };
    }
  }, [
    editionListData,
    mspData,
    skuListData,
    storageRegionListData,
    saasAppsAndEndpointsDatasource,
  ]);

  return [initialValues, useMutation];
};

export default useUpdateMSPForm;
