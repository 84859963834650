import React from "react";
import Tooltip from "orion/lib/tooltip";

const CellRenderer = ({ text, isSuspended, isDeleted }) => {
  return (
    <Tooltip text={text} overflowOnly>
      <div
        className={`oui-text-truncate oui-text-capitalize ${
          isSuspended || isDeleted ? "oui-text-muted" : ""
        }`}
      >
        {text}
      </div>
    </Tooltip>
  );
};

export default CellRenderer;
