import React from "react";
import { Link } from "react-router-dom";
import { Icon } from "orion";
import BackSVG from "assets/icons/back.svg";

const BackButton = ({ to, className }) => {
  return (
    <Link to={to}>
      <Icon src={BackSVG} clickable className={className} />
    </Link>
  );
};
export default BackButton;
