import React from "react";
import { Modal, Button } from "orion";
import { useDispatch } from "react-redux";

import { genericErrorMessage } from "consts";
import { showAlert } from "slices/alertsSlice";
import useSuspendMSP from "queryHooks/useSuspendMSP/useSuspendMSP";
import { useQueryClient } from "react-query";

const SuspendActionModal = ({ key, closeModal, mspId, refetchOnSuccess }) => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const { mutate } = useSuspendMSP(mspId, {
    onError: (e) => {
      dispatch(
        showAlert({
          message: e.data?.message || genericErrorMessage,
          type: "danger",
        })
      );
    },
    onSuccess: () => {
      dispatch(
        showAlert({
          message: "Successfully suspended MSP account.",
          type: "success",
        })
      );
      if (refetchOnSuccess) {
        queryClient.refetchQueries(refetchOnSuccess);
      }
      closeModal();
    },
  });

  return (
    <Modal
      header="Confirmation"
      key={key}
      size="sm"
      isOpen
      toggle={closeModal}
      body={() => (
        <div>
          Admin access to MSP portal will be stopped. Are you sure you want to
          suspend the MSP Account?
        </div>
      )}
      footer={() => {
        return (
          <div>
            <Button label="Cancel" onClick={closeModal} />
            <Button type="primary" label="Suspend" onClick={mutate} />
          </div>
        );
      }}
    />
  );
};

export default SuspendActionModal;
