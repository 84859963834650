export const Allowed_To_Delete = "allowedToDelete";
export const Allowed_To_Delete_Now = "allowedToDeleteNow";
export const Allowed_To_Reset_Password = "allowedToResetPassword";
export const Allowed_To_Reset_Password_Admin_Email =
  "allowedToResetPasswordAdminEmail";
export const Allowed_To_Reset_Password_Expiry_Date =
  "allowedToResetPasswordExpiryDate";

export const UPDATE_PERMISSIONS_FORM_KEY = "UPDATE_PERMISSIONS_FORM";

export const getValidationSchema = (Yup) =>
  Yup.object().shape({
    [Allowed_To_Delete]: Yup.boolean(),
    [Allowed_To_Delete_Now]: Yup.boolean(),
    [Allowed_To_Reset_Password]: Yup.boolean(),
    [Allowed_To_Reset_Password_Admin_Email]: Yup.string().when(
      Allowed_To_Reset_Password,
      {
        is: true,
        then: Yup.string().email("Invalid Email").required("Email is required"),
      }
    ),
  });
