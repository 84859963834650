import React, {
  useMemo,
  createContext,
  useState,
  useContext,
  useEffect,
} from "react";
import axios from "axios";
import { stringify } from "query-string";
import {
  LOGIN_REDIRECT_URL,
  API_REFRESH_TOKEN,
  IS_STORYBOOK,
  DOMAIN,
  cookies,
} from "consts";

import refreshToken from "./refreshToken";

if (process.env.NODE_ENV === "development") {
  cookies.set("refreshToken", "tokenvalue", {
    domain: DOMAIN,
    path: "/",
    secure: true,
    sameSite: "strict",
  });
  const { worker } = require("mocks/worker");
  worker.start();
}

const HttpClientContext = createContext();

if (cookies.get("refreshToken")) {
  cookies.set("msps_refreshToken", cookies.get("refreshToken"), {
    maxAge: 24 * 59 * 60,
    path: "/",
    secure: true,
    sameSite: "strict",
    // ...(!IS_STORYBOOK && { secure: true }),
  });
  cookies.remove("refreshToken", {
    domain: DOMAIN,
    path: "/",
    secure: true,
    sameSite: "strict",
  });
}

if (!cookies.get("msps_refreshToken")) {
  if (!IS_STORYBOOK) {
    window.location = `${LOGIN_REDIRECT_URL}?${stringify({
      redirectUrl: window.location,
    })}`;
  }
  console.log("Redirecting - missing refreshToken cookie");
}

export const HttpClientProvider = ({ children }) => {
  const [accessToken, setAccessToken] = useState(cookies.get("accessToken"));

  const axiosClient = useMemo(() => {
    const httpClient = axios.create();

    httpClient.interceptors.request.use((config) => {
      if (accessToken && config.url !== API_REFRESH_TOKEN && !config.isReady) {
        config.headers["Authorization"] = `Bearer ${accessToken}`;
      }
      return config;
    });

    httpClient.interceptors.response.use(
      (res) => res.data,
      (error) => {
        const originalRequest = error.config;
        if (error.response.status === 401 && !originalRequest.isReady) {
          originalRequest.isReady = true;
          if (cookies.get("msps_refreshToken")) {
            return refreshToken(httpClient, cookies.get("msps_refreshToken"))
              .then(({ access_token: accessToken }) => {
                setAccessToken(accessToken);
                originalRequest.headers[
                  "Authorization"
                ] = `Bearer ${accessToken}`;
                return httpClient(originalRequest);
              })
              .catch((e) => {
                if (e.config.url === API_REFRESH_TOKEN) {
                  if (!IS_STORYBOOK) {
                    window.location = `${LOGIN_REDIRECT_URL}?${stringify({
                      redirectUrl: window.location,
                    })}`;
                  }

                  console.log("Redirecting - unsuccessfully token refreshing.");
                } else {
                  throw e;
                }
              });
          } else {
            if (!IS_STORYBOOK) {
              window.location = `${LOGIN_REDIRECT_URL}?${stringify({
                redirectUrl: window.location,
              })}`;
            }
            console.log("Redirecting - refreshToken expired");
          }
        }
        throw error?.response || error;
      }
    );
    return httpClient;
  }, [accessToken]);

  useEffect(() => {
    const initialAccessToken = cookies.get("accessToken");
    cookies.remove("accessToken", {
      domain: DOMAIN,
      secure: true,
      sameSite: "strict",
    });
    if (!initialAccessToken) {
      refreshToken(axios, cookies.get("msps_refreshToken"))
        .then(({ data: { access_token: accessToken } }) => {
          setAccessToken(accessToken);
        })
        .catch(() => {
          if (!IS_STORYBOOK) {
            window.location = `${LOGIN_REDIRECT_URL}?${stringify({
              redirectUrl: window.location,
            })}`;
          }
          console.log("Redirecting - unsuccessfully token refreshing.");
        });
    }
  }, []);

  return (
    <HttpClientContext.Provider value={axiosClient}>
      {accessToken && children}
    </HttpClientContext.Provider>
  );
};

export function useHttpClient() {
  return useContext(HttpClientContext);
}
