import React from "react";
import { FormGroup, Label } from "orion";

import { genericSelectTypes } from "consts";
import GenericOptionsSelect from "components/GenericOptionsSelect";

export const filters = [
  {
    title: "Products",
    fieldName: "productIDs",
    value: [],
  },
  {
    title: "Storage Regions",
    fieldName: "storageRegions",
    value: [],
  },
  {
    title: "Edition",
    fieldName: "edition",
    value: null,
  },
];

const AccountFilters = (formikProps) => {
  return (
    <>
      <FormGroup>
        <Label text="Product" muted />
        <GenericOptionsSelect
          isMulti
          type={genericSelectTypes.products}
          name="productIDs"
          placeholder="Select"
          formikProps={formikProps}
          isClearable
          value={formikProps.values.productIDs}
        />
      </FormGroup>
      <FormGroup>
        <Label text="Storage Regions" muted />
        <GenericOptionsSelect
          isMulti
          type={genericSelectTypes.storageRegions}
          name="storageRegions"
          placeholder="Select"
          formikProps={formikProps}
          value={formikProps.values.storageRegions}
        />
      </FormGroup>
      <FormGroup className="oui-mb-4">
        <Label text="License Type" muted />
        <GenericOptionsSelect
          className="oui-mb-2"
          isClearable
          type={genericSelectTypes.editions}
          name="edition"
          placeholder="Select"
          formikProps={formikProps}
          value={formikProps.values.edition}
          downgradeAllowed={true}
        />
      </FormGroup>
    </>
  );
};

export default AccountFilters;
