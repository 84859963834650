import { useQuery } from "react-query";
import { API_STORAGE_REGION_LIST } from "consts";
import { useHttpClient } from "contexts/httpClient";

export default function useStorageRegionList(config) {
  const httpClient = useHttpClient();
  return useQuery(
    ["StorageRegionList"],
    () => {
      return httpClient.get(API_STORAGE_REGION_LIST).then((res) => {
        return res;
      });
    },
    config
  );
}
