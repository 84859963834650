/**
 * Converts a boolean value to a corresponding status string or a Yes/No string based on the specified mode.
 * @param {boolean} value - The boolean value indicating the status (enabled or disabled).
 * @param {boolean} isYesNo - If true, returns "Yes" for true and "No" for false. Otherwise, returns "Enabled" for true and "Disabled" for false.
 * @returns {string} The status string or Yes/No string based on the specified mode.
 */
export const getStatusString = (value, isYesNo = false) => {
  if (isYesNo) {
    return value ? "Yes" : "No";
  }
  return value ? "Enabled" : "Disabled";
};
