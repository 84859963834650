import { createSlice } from "@reduxjs/toolkit";

const fullPageLoaderSlice = createSlice({
  name: "fullPageLoader",
  initialState: {
    show: false,
    loaderText: "",
  },
  reducers: {
    showLoader(state) {
      state.show = true;
    },
    hideLoader(state) {
      state.show = false;
    },
    toggleLoader(state) {
      state.show = !state.show;
    },
    setLoaderText(state, { payload }) {
      state.loaderText = payload.message;
    },
  },
});

export const { toggleLoader, showLoader, hideLoader, setLoaderText } =
  fullPageLoaderSlice.actions;

export const selectShow = (state) => state.fullPageLoader.show;
export const loaderText = (state) => state.fullPageLoader.loaderText;

export default fullPageLoaderSlice.reducer;
