import { useMutation, useQueryClient } from "react-query";
import { useDispatch } from "react-redux";
import { API_MSP_LIST_V2, QUERIES } from "consts";
import { useHttpClient } from "contexts/httpClient";
import { pollTaskStatus } from "queryHooks/useTaskStatus/useTaskStatus";

export default function useCreateMSP(config) {
  const httpClient = useHttpClient();
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const createMSP = (data) =>
    new Promise(async (resolve, reject) => {
      try {
        const res = await httpClient.post(API_MSP_LIST_V2, data);
        pollTaskStatus(httpClient, res?.task?.id, resolve, reject, dispatch);
      } catch (err) {
        reject(err);
      }
    });
  return useMutation((data) => createMSP(data), {
    ...config,
    onSuccess: (data) => {
      queryClient.resetQueries(QUERIES.getMspList);
      config?.onSuccess?.(data);
    },
  });
}
