import { createSlice } from "@reduxjs/toolkit";

const alertsSlice = createSlice({
  name: "alerts",
  initialState: {
    alerts: [],
  },
  reducers: {
    showAlert(state, { payload }) {
      state.alerts.push(payload);
    },
    hideAlert(state, { payload }) {
      state.alerts = state.alerts.filter(
        (alert) => alert.message !== payload.message
      );
    },
    hideAllAlerts(state) {
      state.alerts = [];
    },
  },
});

export const { showAlert, hideAlert, hideAllAlerts } = alertsSlice.actions;

export const alerts = (state) => state.alerts.alerts;

export default alertsSlice.reducer;
