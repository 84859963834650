import { useQuery } from "react-query";
// import { API_EDITION_LIST } from "consts";
// import { useHttpClient } from "contexts/httpClient";

const temporaryEditionList = {
  totalCount: 2,
  list: [
    {
      id: "evaluation",
      name: "Evaluation",
    },
    {
      id: "commercial",
      name: "Commercial",
    },
  ],
};

export default function useEditionList(config) {
  // const httpClient = useHttpClient();
  return useQuery(
    ["EditionList"],
    () => {
      return new Promise((resolve) => resolve(temporaryEditionList));
      //return httpClient.get(API_EDITION_LIST);
    },
    config
  );
}
