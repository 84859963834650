import { useQuery } from "react-query";
// import { API_SKU_LIST } from "consts";
// import { useHttpClient } from "contexts/httpClient";

const temporarySkuList = {
  totalCount: 3,
  list: [
    {
      id: "business",
      name: "Business",
    },
    {
      id: "enterprise",
      name: "Enterprise",
    },
    {
      id: "elite",
      name: "Elite",
    },
  ],
};

export default function useSkuList(config) {
  //const httpClient = useHttpClient();
  return useQuery(
    ["SkuList"],
    () => {
      return new Promise((resolve) => resolve(temporarySkuList));
      //return httpClient.get(API_SKU_LIST);
    },
    config
  );
}
