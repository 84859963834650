import { useQuery } from "react-query";

const { default: useMspProducts } = require("./useMspProducts");

const useProductDatasource = (config) => {
  const { data } = useMspProducts();
  const { productId } = config;

  return useQuery(
    "ProductDatasource",
    () => {
      const { datasources } = data.find(
        (product) => product.productID === productId
      );
      return datasources;
    },
    { enabled: !!data }
  );
};

export default useProductDatasource;
