import React, { useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Toast } from "orion";
import { alerts as orionAlerts, hideAlert } from "slices/alertsSlice";

const ToastRenderer = () => {
  const alerts = useSelector(orionAlerts);
  const dispatch = useDispatch();
  const closeAlert = useCallback(
    (payload) => {
      dispatch(hideAlert(payload));
    },
    [dispatch]
  );

  return <Toast alerts={alerts} onClose={closeAlert} />;
};

export default ToastRenderer;
