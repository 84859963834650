import React, { useCallback, useRef } from "react";
import { Wizard, Form } from "orion";
import { useDispatch } from "react-redux";
import { addDays, endOfDay, isBefore } from "date-fns";
import { asUTC } from "utils/timezone";

import {
  dataSourceIdToDSName,
  genericErrorMessage,
  PRODUCT_ID,
  QUERIES,
} from "consts";
import { showAlert } from "slices/alertsSlice";
import useCreateMSP from "queryHooks/useCreateMSP/useCreateMSP";

import FormContainer from "./FormContainer";
import styles from "./AddMSPWizard.module.scss";
import { useQueryClient } from "react-query";

const validationSchemaCreator = (Yup) => {
  return Yup.object().shape({
    organizationName: Yup.string().max(30, "Max 30 chars").required("Required"),
    sfdcId: Yup.string()
      .required("Required")
      .matches(/^[a-z0-9]+$/i, {
        excludeEmptyString: true,
        message: "Invalid SFDC ID. Only alphanumeric characters allowed.",
      })
      .test(
        "start",
        "Must start with '001'",
        (val) => (val || "").substr(0, 3) === "001"
      )
      .test(
        "length",
        "Must be exactly 15 or 18 characters",
        (val) => (val || "").length === 15 || (val || "").length === 18
      ),
    edition: Yup.mixed().required("Required"),
    timezone: Yup.mixed().required("Required"),
    expiryDate: Yup.date()
      .nullable()
      .required("Required")
      .test(
        "edition",
        "You can evaluate for a maximum of 30 days",
        function (value) {
          const { edition } = this.parent;
          if (edition?.value === "evaluation") {
            return isBefore(value, addDays(endOfDay(new Date()), 31));
          }
          return true;
        }
      ),
    user: Yup.object().shape({
      firstName: Yup.string().required("Required").max(30, "Max 30 chars"),
      lastName: Yup.string().required("Required").max(30, "Max 30 chars"),
      email: Yup.string()
        .email("Enter a valid email address.")
        .required("Required")
        .max(100, "Max 100 chars")
        .test(
          "restricted emails",
          "This email is part of restricted public domains: Please use your corporate email address.",
          (value) => {
            const whitlistedDomains = ["yahoo", "gmail", "hotmail", "rediff"];
            return !whitlistedDomains.some((domain) =>
              value?.includes(`@${domain}`)
            );
          }
        ),
      phone: Yup.string().required("Required").max(30, "Max 30 chars"),
    }),
    products: Yup.array()
      .of(
        Yup.object().shape({
          skus: Yup.array().test("enabled", "Required", function (value) {
            const { enabled } = this.parent;
            if (enabled) {
              return value.length > 0;
            }
            return true;
          }),
          storageRegions: Yup.array().test(
            "enabled",
            "Required",
            function (value) {
              const { enabled } = this.parent;
              if (enabled) {
                return value.length > 0;
              }
              return true;
            }
          ),
          datasources: Yup.array().test(
            "enabled",
            "Required",
            function (value) {
              const { enabled, productID } = this.parent;
              if (enabled && productID === PRODUCT_ID.saasAppsAndEndpoints) {
                return value.length > 0;
              }
              return true;
            }
          ),
        })
      )
      .test("products", function (value) {
        return value.some((v) => v.enabled);
      }),
  });
};

const AddMSPWizard = ({ toggle }) => {
  const formRef = useRef();

  const dispatch = useDispatch();

  const queryClient = useQueryClient();

  const { mutate: createMSP } = useCreateMSP();

  const onSubmitHandler = useCallback(
    ({ edition, timezone, expiryDate, products, user, ...values }, formik) => {
      createMSP(
        {
          ...values,
          edition: edition.value,
          timezone: timezone.value,
          expiryDate: asUTC(endOfDay(expiryDate)).toISOString(),
          user: { ...user, role: 2 },
          products: products
            .filter(({ enabled }) => enabled)
            .map(({ productID, skus, storageRegions, datasources }) => ({
              productID,
              skus: skus.map(({ value }) => value),
              storageRegions: storageRegions.map(({ value }) => value),
              datasources:
                productID === PRODUCT_ID.hybridWorkloads
                  ? [
                      {
                        name: "Hybrid Workloads",
                        isEnabled: true,
                      },
                    ]
                  : datasources.map((datasource) => ({
                      name: dataSourceIdToDSName[datasource.value],
                      isEnabled: true,
                    })),
            })),
        },
        {
          onError: (e) => {
            if (e.status === 411) {
              // TODO update after initialErrors props will be handled by Form
              formik.setErrors(e.data);
            } else {
              dispatch(
                showAlert({
                  message: e.data?.message || genericErrorMessage,
                  type: "danger",
                })
              );
            }
          },
          onSuccess: (data) => {
            if (data?.warning) {
              dispatch(
                showAlert({
                  message: data?.message,
                  type: "warning",
                })
              );
            } else {
              dispatch(
                showAlert({
                  message: "MSP account created successfully",
                  type: "success",
                })
              );
              queryClient.refetchQueries([QUERIES.getMspList]);
            }
            toggle();
          },
        }
      );
    },
    [createMSP, dispatch, queryClient, toggle]
  );

  return (
    <Form
      withValidation
      initialValues={{
        organizationName: "",
        sfdcId: "",
        edition: "",
        timezone: "",
        expiryDate: "",
        user: { firstName: "", lastName: "", email: "", phone: "" },
        products: [
          {
            enabled: false,
            productID: 1,
            skus: [],
            storageRegions: [],
            datasources: [],
          },
          {
            enabled: false,
            productID: 2,
            skus: [],
            storageRegions: [],
            datasources: [],
          },
        ],
      }}
      validationSchema={validationSchemaCreator}
      innerRef={formRef}
      onSubmit={onSubmitHandler}
      renderChildren={({ submitForm, ...formikProps }) => {
        return (
          <Wizard
            className={styles.wizard}
            isOpen={true}
            toggle={toggle}
            header="Add MSP"
            items={[{ component: () => <FormContainer {...formikProps} /> }]}
            onFinishClick={submitForm}
            disableFinish={!formikProps.isValid}
            finishLabel="Save"
            dataTestId="sportal-add-msp-container"
          />
        );
      }}
    />
  );
};

export default AddMSPWizard;
