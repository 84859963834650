import { useMutation, useQueryClient } from "react-query";
import { API_MSP_PERMISSIONS } from "consts";
import { useHttpClient } from "contexts/httpClient";
import withUrlParams from "utils/withUrlParams";

export default function useUpdateMSPPermission(mspId, config) {
  const httpClient = useHttpClient();
  const queryClient = useQueryClient();
  return useMutation(
    (data) =>
      httpClient.patch(withUrlParams(API_MSP_PERMISSIONS, { mspId }), data),
    {
      ...config,
      onSuccess: (data) => {
        queryClient.invalidateQueries("MSP");
        config?.onSuccess?.(data);
      },
    }
  );
}
