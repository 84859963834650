import { API_TASK, CAM_JOBS_STATUS } from "consts";
import { setLoaderText } from "slices/fullPageLoaderSlice";
import withUrlParams from "utils/withUrlParams";

export const pollTaskStatus = (
  httpClient,
  taskId,
  resolve,
  reject,
  dispatch
) => {
  let count = 0;
  let interval = setInterval(async () => {
    count++;
    try {
      const jobData = await httpClient.get(withUrlParams(API_TASK, { taskId }));
      if (jobData.status === CAM_JOBS_STATUS.finished) {
        if (jobData?.output?.failed) {
          dispatch(setLoaderText({ message: "" }));
          reject({ data: jobData?.output?.errorResp });
          clearInterval(interval);
        } else {
          dispatch(setLoaderText({ message: "" }));
          resolve(jobData?.output?.successResp);
          clearInterval(interval);
        }
      }
      if (count === 2) {
        dispatch(
          setLoaderText({
            message: "Operation is in progress. This could take a while...",
          })
        );
      }
      if (count === 36) {
        dispatch(setLoaderText({ message: "" }));
        resolve({
          warning: true,
          message:
            "The current process is taking longer than expected. We will notify you once it is complete.",
        });
        clearInterval(interval);
      }
    } catch (err) {
      reject(err);
    }
  }, 5000);
};
