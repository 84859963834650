import React from "react";
import { Loader } from "orion";
import { useSelector } from "react-redux";
import { useIsMutating, useIsFetching } from "react-query";
import { loaderText } from "slices/fullPageLoaderSlice";

const FullPageLoader = () => {
  const text = useSelector(loaderText);

  const mutationsNumber = useIsMutating();
  const fetchingsNumber = useIsFetching({
    predicate: (query) => {
      if (query.options?.meta?.disableFullPageLoader) {
        return false;
      }
      return (
        // to not show full page loader for fetching nextpages
        query.isFetching && (query.state.data?.pageParams || []).length < 3
      );
    },
  });
  return (
    <Loader
      isLoading={!!(mutationsNumber + fetchingsNumber)}
      text={text}
      fullPage
      dataTestId="full-page-loader"
    />
  );
};

export default FullPageLoader;
